import { HTTP_METHOD } from '../constants';
import { env, applovin_dash, api_url } from '../config';
import noImageIcon from '../assets/images/no_image.svg';
import noAdvertiserIcon from '../assets/images/no_advertiser.svg'

export const getRsidValue = () => {
  if (location.hash) return location.hash.split("=")[1];
}

export const urlBuilder = (reportType, extraHeader = '', queryParams = {}) => {
  let queryParamsString = Object.entries(queryParams).map((e) => { return `${e[0]}=${e[1]}`; }).join("&");

  if (queryParamsString) queryParamsString = ("?" + queryParamsString);

  if (!extraHeader || extraHeader === '') return encodeURI(`${api_url}/${reportType}${queryParamsString}`);

  return encodeURI(`${api_url}/${reportType}/${extraHeader}${queryParamsString}`);
}

export const isPathStructureIdentical = (pathname, path) => {
  const pathnameParts = pathname.split('/')
  const pathParts = path.split('/')

  return pathnameParts.every((part, ind) => part === pathParts[ind] || pathParts[ind]?.startsWith(':'))
}

export function openNewTab(page) {
  // add /o when opening new tabs to keep wrapper
  window.open(`${env !== 'local' ? `${applovin_dash}/o/adreview` : ''}${page}`, '_blank');
}

export function formatNumberWithCommas(num) {

  return num.toLocaleString('en');
}
export function getNumberFromLocalString(data) {
  return parseInt(data.replace(/\D/g, ''));
}
export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
export function getNumberOrString(data, isNumber) {
  return isNumber ? getNumberFromLocalString(data) : data.toLowerCase();
}
export function getWebDomainTitles(webDomains) {
  return webDomains.map(webDomain => ({
    label: webDomain.startsWith('free:') ? `${webDomain.split(':')[1]} (free text)` : webDomain,
    value: webDomain
  }))
}

export function getTitles(values, package_names, itunes_ids) {
  let titles = [];
  values.forEach((e) => {
    if (e.startsWith("package:")) {
      const info = package_names[e.split(":")[1]];
      titles.push({ value: e, label: `${info.name} (${info.developer})`, platform: 'android', labelNote: e.platform });
    } else if (e.startsWith("itunes:")) {
      const info = itunes_ids[e.split(":")[1]];
      titles.push({ value: e, label: `${info.name} (${info.developer})`, platform: 'ios', labelNote: e.platform });
    } else if (e.startsWith("free:")) {
      titles.push({ value: e, label: `${e.split(":")[1]} (free text)` });
    }
  });

  return titles;
}

export const getMultipleTitles = (values, packageNames, itunesIds) => {
  return values.map(valueObj => {
    // values can be an array of ['a', 'b', 'c'] or an array of 
    // [{value: 'a', label: '...'} , {value: 'b', label: '...'}, ...]
    // because it comes from different. The next line address this and 
    // reformat the rule.value as [{ value: ... , label: ...}, ...]
    const value = valueObj['value'] || valueObj;
    if (value.startsWith("package:")) {
      const { name, developer } = packageNames[value.split(":")[1]];
      return { value, label: `${name}${developer ? ` (${developer})` : ''}`, labelNote: 'android', platform: 'android' };
    }
    if (value.startsWith("itunes:")) {
      const { name, developer } = itunesIds[value.split(":")[1]];
      return { value, label: `${name}${developer ? ` (${developer})` : ''}`, labelNote: 'ios', platform: 'ios' };
    }
    if (value.startsWith("free:")) {
      return { value, label: `${value.split(":")[1]} (free text)` };
    }
  });
}



export function buildRuleObject({ id, name, platform, advertiser, iconUrl, webDomain }) {
  const titleData = {};
  const rule = { platform };

  if (webDomain) {
    rule.ruleType = 'web_domain';
    rule.value = [{ value: webDomain, label: webDomain }];
  } else if (rule.platform === 'android') {
    rule.ruleType = 'title';
    rule.value = [{ value: `package:${id}`, label: `${name} (${advertiser})` }];
    titleData.id = {
      name: name,
      developer: advertiser,
      icon: iconUrl
    }
  } else if (rule.platform === 'ios') {
    rule.ruleType = 'title';
    rule.value = [{ value: `itunes:${id}`, label: `${name} (${advertiser})` }];
    titleData.id = {
      name: name,
      developer: advertiser,
      icon: iconUrl
    }
  }

  return { rule, titleData };
}


export function getTitleIcon(isWebAdvertiser, title, originalIcon) {
  let icon = originalIcon;
  if (isWebAdvertiser) { // website
    icon = noImageIcon;
  } else {
    if (title || originalIcon) { // regular
      icon = originalIcon
    } else { // nothing was found - unresolved 
      icon = noAdvertiserIcon;
    }
  }

  return icon;
}

export const labelsAsWebSiteFilters = labelArray => labelArray.map(label => ({ label, value: label }));


export const getPostRequestBodyWithCredentials = content => {
  return getPostRequestBody(content, true);
};

export const getPostRequestBody = (content, withCredentials = false) => {
  const body = {
    headers: { 'Content-Type': 'application/json' },
    method: HTTP_METHOD.POST,
    body: JSON.stringify(content),
  }
  if (withCredentials) {
    body.credentials = 'include'
  }
  return body;
};