import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import Loader from './Loader';

export type SpinnerProps = HTMLAttributes<HTMLDivElement> & {
    width?: number | string
    height?: number | string
}

const Spinner: FC<SpinnerProps> = ({ width = 75, height, ...otherProps }) => {
    return (
        <Wrapper {...otherProps}>
            <Loader width={width} height={height} />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
`;

export default Spinner;