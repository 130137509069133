import React, { FC } from 'react';
import { DefaultTheme, GlobalStyle } from 'UI-Dash-Commons-UI';

import { env } from 'config';
import useUserEmail from 'hooks/useUserEmail';
import useFrame from 'hooks/useFrame';
import AppRoutes from './AppRoutes';
import Breadcrumbs from './Breadcrumbs';
import Navigation from './Navigation';

const App: FC<{}> = () => {
    useUserEmail();

    useFrame();

    return (
        <DefaultTheme>
            <>
                <GlobalStyle />
                <Breadcrumbs />
                {env === 'local' && <Navigation />}
                <AppRoutes />
            </>
        </DefaultTheme>
    );
}
 
export default App;
 