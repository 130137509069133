import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { isPathStructureIdentical } from 'helpers/utils';
import { dispatchReplaceLocation } from 'utils/thirdPartyMessageDispatcher';
import useStore from './useStore';
import { routes } from 'components/AppRoutes';

/* Test Commands */

// window.postMessage({ type: 'location_change_replace', url: '/campaigns' })
// window.postMessage({ type: 'location_change_replace_no_section_change', url: '/campaigns' })

const useFrame = () => {
    const breadcrumbs = useStore(state => state.breadcrumbs);

    const history = useHistory();

    const location = useLocation();

    useEffect(() => {
        const handleFrameChange = (event: MessageEvent) => {
            if (event.data.type !== 'iframe_location_change') return;
    
            const targetUrl = event.data.url.toLowerCase().replace('/adreview', '');
    
            history.push(targetUrl);
        }

        window.addEventListener('message', handleFrameChange);

        return () => window.removeEventListener('message', handleFrameChange);
    }, [])

    useEffect(() => {
        if (history.action !== 'POP') return;

        const route = routes.find(({ path }) => isPathStructureIdentical(location.pathname, path));

        if (!route) return;

        // breadcrumbs length of 1 or 2 ensures that the current page is a section
        const updateActiveSection = route.section && breadcrumbs.length <= 2;

        dispatchReplaceLocation(`${location.pathname}${location.hash}`, { updateActiveSection });
    }, [history, location])
}

export default useFrame;