import { create } from 'zustand'

import { GenericAutosuggestion, GroupedAutosuggestion } from 'utils/dataManipulations';
import type { Creative } from 'components/ImagesSlider';

type User = {
    id: number
    email: string
    firstName: string
    lastName: string
}

type Users = {
    loggedInUser: User
    queriesUser: User
    superusedInto: User | undefined
}

export type Info = {
    displayEmails: string
    dropdownSelectedEmail: string
    isAdmin: boolean
    isMpa: boolean
    reportAdSubject: string
    users: Users
}

export type Autosuggestions = {
    advisories: GroupedAutosuggestion
    adFormats: GenericAutosuggestion
    appIds: any[]
    apps: { [x: string]: any }[]
    contentRating: GroupedAutosuggestion
    riskyCategories: GenericAutosuggestion
    sdkUids: GenericAutosuggestion
    storeCategories: GroupedAutosuggestion
    unifiedStoreCategories: GenericAutosuggestion
}

export type Breadcrumb = {
    pathname?: string
    hash?: string
    label: string
}

type SelectedCreative = Creative & {
    reported?: boolean
}

type Store = {
    userEmail?: string
    setUserEmail: (email: string) => void
    clearUserEmail: () => void
    userEmailFetched: boolean

    info?: Info
    setInfo: (info: Info) => void
    autosuggestions?: Autosuggestions
    setAutosuggestions: (autosuggestions: Autosuggestions) => void
    fetchingInfo: boolean
    setFetchingInfo: (fetching: boolean) => void
    infoError: any
    setInfoError: (error: any) => void
    clearInfo: () => void
    
    breadcrumbs: Breadcrumb[]
    getBreadcrumbsString: () => string
    setBreadcrumb: (breadcrumb: Breadcrumb) => void
    pushBreadcrumb: (breadcrumb: Breadcrumb) => void
    replaceBreadcrumb: (breadcrumb: Breadcrumb) => void
    popBreadcrumb: (num?: number) => void

    hiddenTips: string[]
    hideTip: (tip: string) => void

    selectedCreatives: SelectedCreative[]
    setSelectedCreatives: (creatives: SelectedCreative[]) => void
}

const useStore = create<Store>((set, get) => ({
    userEmail: undefined,
    setUserEmail: email => set({ userEmail: email, userEmailFetched: true }),
    clearUserEmail: () => set({ userEmail: undefined, userEmailFetched: false }),
    userEmailFetched: false,
    
    info: undefined,
    setInfo: info => set({ info }),
    autosuggestions: undefined,
    setAutosuggestions: autosuggestions => set({ autosuggestions }),
    fetchingInfo: false,
    setFetchingInfo: fetching => set({ fetchingInfo: fetching }),
    infoError: undefined,
    setInfoError: error => set({ infoError: error }),
    clearInfo: () => set({ userEmail: undefined, info: undefined, autosuggestions: undefined, infoError: undefined }),
    
    breadcrumbs: [],
    getBreadcrumbsString: () => get()?.breadcrumbs?.map(({ label }) => label).join(' > '),
    setBreadcrumb: ({ pathname = location.pathname, hash = location.hash, label }) => set({ breadcrumbs: [{ pathname, hash, label }] }),
    pushBreadcrumb: ({ pathname = location.pathname, hash = location.hash, label }) => {
        if (get()?.breadcrumbs[get()?.breadcrumbs.length - 1]?.label === label) return
        
        set(state => ({ breadcrumbs: [...state.breadcrumbs, { pathname, hash, label }] }))
    },
    replaceBreadcrumb: ({ pathname = location.pathname, hash = location.hash, label }) => set(state => ({ breadcrumbs: [...state.breadcrumbs.slice(0, -1), { pathname, hash, label }] })),
    popBreadcrumb: (number = 1) => set(state => ({ breadcrumbs: state.breadcrumbs.slice(0, -number) })),

    hiddenTips: [],
    hideTip: tip => set(state => ({ hiddenTips: [...state.hiddenTips, tip] })),

    selectedCreatives: [],
    setSelectedCreatives: creatives => set({ selectedCreatives: creatives }),
}))

export default useStore;