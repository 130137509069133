import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

const PageNotFound: FC<HTMLAttributes<HTMLParagraphElement>> = () => <Message>Unable to load page</Message>

const Message = styled.p`
    display: block;
    width: fit-content;
    margin: 20% auto 0;
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 14px;
`;

export default PageNotFound;