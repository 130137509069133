import React, { FC, ImgHTMLAttributes, MutableRefObject } from 'react'
import styled from 'styled-components'

import spinnerIcon from 'assets/images/spinner.svg';

export const VARIANTS = {
    NORMAL: 'normal',
    SECTION: 'section'
}

const VARIANT_STYLES = {
    [VARIANTS.NORMAL]: { width: '36px', margin: '0' },
    [VARIANTS.SECTION]: { width: '36px', margin: '25px auto 0' }
}

export type LoaderProps = ImgHTMLAttributes<HTMLImageElement> & {
    loaderRef?: MutableRefObject<HTMLImageElement>
    variant?: typeof VARIANTS[keyof typeof VARIANTS]
}

const Loader: FC<LoaderProps> = ({ loaderRef, variant = VARIANTS.NORMAL, ...otherProps }) => {
  return <Spinner variant={variant} ref={loaderRef} src={spinnerIcon} {...otherProps} />
}

const Spinner = styled.img`
  display: block;
  width: ${({ variant, width }) => width || VARIANT_STYLES[variant].width};
  margin: ${({ variant, margin }) => margin || VARIANT_STYLES[variant].margin};
  animation: rotateSpinner 0.8s linear infinite;
`;

export default Loader