const environments = {
  'localhost': 'local',
  'dash-safedk-new.dev-applovin.com': 'dev',
  'stage-dash.safedk.applovin.com': 'stage',
  'prod-dash.safedk.applovin.com': 'prod'
};

const endpoints = {
  local: 'http://localhost:3000',
  dev: 'https://api-safedk.dev-applovin.com',
  stage: 'https://stage-api.safedk.applovin.com',
  prod: 'https://api-safedk.applovin.com'
}

const applovinDashUrls = {
  local: 'http://localhost:3009',
  dev: 'https://dash.dev-applovin.com',
  stage: 'https://stage-dash.applovin.com',
  prod: 'https://dash.applovin.com'
}

export const env = environments[location.hostname];
export const base_url = endpoints[env];
export const api_url = `${base_url}/v1`;

let applovin_dash = undefined;

if (location.ancestorOrigins && location.ancestorOrigins.length > 0) {
  applovin_dash = location.ancestorOrigins[0];
} else {
  applovin_dash = applovinDashUrls[env];
}

export { applovin_dash };
