import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import useStore from 'hooks/useStore';

const Routes = {
    Review: [{ path: '/creatives', label: 'Creatives' }, { path: '/risky_content', label: 'Risky Content' }, { path: '/competitive', label: 'Competitors' }, { path: '/campaigns', label: 'Campaigns' }],
    Manage: [{ path: '/manage/risky_content', label: 'Risky Content Settings' }, { path: '/manage/competitors', label: 'Competitor Settings' }],
    'Admin / Analyze': [{ path: '/reports/intel_impressions', label: 'Intel Impression Report' }, { path: '/matching_data_report', label: 'Matching Data Report' }, { path: '/user_journey_stats', label: 'User Journey Stats' }, { path: '/reports/redirects_report', label: 'Redirects Intel Report' }, { path: '/reports/intel_redirects', label: 'Redirect Report (Old)' }, { path: '/user_journey/reports', label: 'User Journey' }]
}

const Navigation: FC<{}> = () => {
    const { breadcrumbs } = useStore();

    const onLinkClick = event => {
        event.preventDefault();
        window.postMessage({ type: 'iframe_location_change', url: event.target.pathname });
    }

    return (
        <Wrapper>
            {Object.entries(Routes).map(([section, routes]) => (
                <Section key={section}>
                    <SectionTitle>{section}</SectionTitle>
                    
                    <List>
                        {routes.map(({ path, label }) => (
                            <StyledLink 
                                key={path} 
                                to={path} 
                                isActive={() => path === location.pathname || label === breadcrumbs[0]?.label} 
                                onClick={onLinkClick}
                            >
                                {label}
                            </StyledLink>
                        ))}
                    </List>
                </Section>
            ))}
        </Wrapper>
    );
}

const Wrapper = styled.nav`
    display: flex;
    font-family: AL-AvenirNext-Regular, sans-serif;
    margin: 20px;
`;

const Section = styled.div`
    margin-right: 60px;
`;

const SectionTitle = styled.h3`
    margin-top: 0;
    font-size: 9px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: rgb(128, 141, 152);
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 15px;
`;

const StyledLink = styled(NavLink)`
    margin: 4px 0;
    font-family: AL-AvenirNext-Regular, sans-serif;
    font-size: 14px;
    text-decoration: none;
    color: inherit;
    cursor: pointer;

    :hover, :active, :visited {
        color: inherit;
    }

    &.active {
    font-family: AL-AvenirNext-Medium, sans-serif;
    color: rgb(94, 189, 222);
  }
`;

export default Navigation;
