import { useState, useRef, useCallback, useLayoutEffect } from 'react'
import { unstable_batchedUpdates as batch } from 'react-dom'
import axios, { AxiosRequestConfig, AxiosError } from 'axios'
import camelCase from 'lodash/camelCase';

axios.defaults.withCredentials = true;

export const camelCasedKeys = obj => {
    if (Array.isArray(obj)) return obj.map(val => camelCasedKeys(val))
    if (obj && obj.constructor === Object) {
        return Object.keys(obj).reduce((acc, key) => ({ ...acc, [camelCase(key)]: camelCasedKeys(obj[key]) }), {})
    }

    return obj
}


type Store = {
  [x: string]: any
}

export type UseAxiosRequestConfig = AxiosRequestConfig & {
  uuid?: string
  manual?: boolean
  immutable?: boolean
  keepPreviousData?: boolean,
  allowConcurrentRequests?: boolean,
  camelCased?: boolean
  purgeNull?: boolean
  onSuccess?: (res: { status: number; data?: any }) => void
  onError?: (res: { status?: number; error: AxiosError | Error; data?: any }) => void
}

export type UseAxiosResponse = {
  loading: boolean
  status?: number
  error?: any
  data?: any
  activate: (config?: UseAxiosRequestConfig) => Promise<{ status: number; data?: any; error?: any }>
}

const store: Store = {}

const useAxios = ({
  uuid,
  manual,
  immutable,
  keepPreviousData,
  allowConcurrentRequests,
  camelCased,
  purgeNull,
  onSuccess: initialOnSuccess,
  onError: initialOnError,
  ...initialAxiosOptions
}: UseAxiosRequestConfig): UseAxiosResponse => {
  const [status, setStatus] = useState<number | undefined>()
  const [error, setError] = useState<number | undefined>()
  const [data, setData] = useState<any>(uuid ? store[uuid] : undefined)
  const [loading, setLoading] = useState(!manual && (!immutable || !data))

  const fetching = useRef(false);
  const controller = useRef(new AbortController());

  useLayoutEffect(() => {
    if (!manual && (!immutable || !data)) fetchData()
  }, [manual, immutable])

  const fetchData = useCallback(
    async ({ onSuccess = initialOnSuccess, onError = initialOnError, ...axiosOptions } = {}) => {
      if (!allowConcurrentRequests && fetching.current) controller.current.abort();

      controller.current = new AbortController();
      fetching.current = true;

      setLoading(true)
      setStatus(undefined)
      setError(undefined)

      if (!keepPreviousData && !uuid) setData(undefined)

      try {
        let { status, data = undefined } = await axios({
          ...initialAxiosOptions,
          ...axiosOptions,
          transformResponse: purgeNull ? res => res : undefined,
          signal: controller.current.signal
        })

        if (data) {
          if (purgeNull) data = JSON.parse(data, (_, value) => (value === null ? undefined : value))
          if (camelCased) data = camelCasedKeys(data)
        }

        onSuccess?.({ status, data })

        fetching.current = false;

        batch(() => {
          setLoading(false)
          setStatus(status)
          setData(data)
        })

        if (uuid) store[uuid] = data

        return { status, data: data }
      } catch (error: any) {
        const status = error.response?.status

        if (error.message === 'canceled') return { status, error }

        onError?.({ status, error })

        fetching.current = false;

        batch(() => {
          setLoading(false)
          setStatus(status)
          setError(error)
        })

        return { status, error }
      }
    },
    []
  )

  return { loading, status, error, data, activate: fetchData }
}

export { useAxios as default, axios }