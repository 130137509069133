
import { useEffect } from 'react';

import getQuery from 'utils/getQuery';
import { getRsidValue, urlBuilder } from 'helpers/utils';
import useStore from 'hooks/useStore';
import useAxios from 'hooks/useAxios';

const useUserEmail = () => {
    const setUserEmail = useStore(state => state.setUserEmail);

    const query = getQuery();
    const rsid = getRsidValue();

    const { activate: fetchUserEmail } = useAxios({
        method: 'post',
        url: urlBuilder(`ad_intelligence/get_filters/${rsid}`),
        data: { filters: {}, [query.key]: query.data || undefined },
        withCredentials: true,
        manual: true,
        camelCased: true,
        onSuccess: ({ data: { userEmail } }) => setUserEmail(userEmail)
    })

    useEffect(() => {
        if (rsid) fetchUserEmail();
    }, [])
}

export default useUserEmail;